<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">管理员列表</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center margin-10-0">
				<router-link :to="{name:'store.user/add'}">
					<el-button type="primary" size="mini">添加管理员</el-button>
				</router-link>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%"
				>
				<el-table-column align="center" label="用户ID" prop="store_user_id"></el-table-column>
				<el-table-column align="center" label="用户名" prop="user_name"></el-table-column>
				<el-table-column align="center" label="姓名" prop="real_name"></el-table-column>
				<el-table-column align="center" label="添加时间" prop="create_time"></el-table-column>
				<el-table-column align="center" label="操作" width="150">
					<template slot-scope="scope">
						<router-link :to="{name:'store.user/edit',params: {store_user_id:scope.row.store_user_id}}"
							v-auth="'store.role/edit'">
							<el-button v-if="scope.row.is_super != 1" type="text" size="mini" class="table-btn">修改
							</el-button>
						</router-link>
						<el-button v-if="scope.row.is_super != 1" type="text" size="mini" @click="deleteItem(scope.row)"
							class="table-last-btn">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page" background
					@current-change="pageChange" layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				lists: [],
				search: {
					s: 'store/store.user/index',
					keyword: '',
					page: 1,
				},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					let data = res.data.data
					this.lists = data
				}).catch(err => {})
			},
			// 删除单个
			deleteItem(data) {
				this.$confirm('是否要删除该用户吗?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/store.user/delete',
							store_user_id: data.store_user_id,
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>

<style>
</style>
